import myAxios from "@/utils/myRequest";
// /api/materialStatistic 安全保障
export const getMaterialStatistic = (configContent) => {
  let formdata = new FormData();
  for (let key in configContent) {
    formdata.append(key, configContent[key]);
  }
  return myAxios({
    url: `/api/materialStatistic`,
    method: "post",
    data: formdata,
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
// 模块列表数据/api/materialList
export const getMaterialList = (configContent) => {
  let formdata = new FormData();
  for (let key in configContent) {
    formdata.append(key, configContent[key]);
  }
  return myAxios({
    url: `/api/materialList`,
    method: "post",
    data: formdata,
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
// /api/verifyCustomer 客服验证
export const getVerifyCustomer = (configContent) => {
  let formdata = new FormData();
  for (let key in configContent) {
    formdata.append(key, configContent[key]);
  }
  return myAxios({
    url: `/api/verifyCustomer`,
    method: "post",
    data: formdata,
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
// /api/recentOrder 成交快报
export const getRecentOrder = (configContent) => {
  let formdata = new FormData();
  for (let key in configContent) {
    formdata.append(key, configContent[key]);
  }
  return myAxios({
    url: `/api/recentOrder`,
    method: "post",
    data: formdata,
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
// api/verifySeller 黑号验证
export const getVerifySeller = (configContent) => {
  let formdata = new FormData();
  for (let key in configContent) {
    formdata.append(key, configContent[key]);
  }
  return myAxios({
    url: `/api/verifySeller`,
    method: "post",
    data: formdata,
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
// /api/materialDetail 模块详情
export const getMaterialDetail = (configContent) => {
  let formdata = new FormData();
  for (let key in configContent) {
    formdata.append(key, configContent[key]);
  }
  return myAxios({
    url: `/api/materialDetail`,
    method: "post",
    data: formdata,
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
// api/admin/getConfigByKey 追讨案例
export const getConfigByKey = (configContent) => {
  let formdata = new FormData();
  for (let key in configContent) {
    formdata.append(key, configContent[key]);
  }
  return myAxios({
    url: `/api/admin/getConfigByKey`,
    method: "post",
    data: formdata,
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

export const getConfig = (configContent) => {
  let formdata = new FormData();
  for (let key in configContent) {
    formdata.append(key, configContent[key]);
  }
  return myAxios({
    url: `/api/getConfig`,
    method: "post",
    data: formdata,
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
// api/xbAdUser
export const getXbAdUser = (configContent) => {
  let formdata = new FormData();
  for (let key in configContent) {
    formdata.append(key, configContent[key]);
  }
  return myAxios({
    url: `/api/xbAdUser`,
    method: "post",
    data: formdata,
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
//  api/getUuid
export const getUuid = () => {
  return myAxios({
    url: `/api/getUuid`,
    method: "post",
  });
};
// api/xbDownloadApp
export const getXbDownloadApp = (configContent) => {
  let formdata = new FormData();
  for (let key in configContent) {
    formdata.append(key, configContent[key]);
  }
  return myAxios({
    url: `/api/xbDownloadApp`,
    method: "post",
    data: formdata,
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
// api/submitRecoveryLog 上报回收日志
export const getSubmitRecoveryLog = (config) => {
  let configContent = Object.assign(config);
  let formdata = new FormData();
  for (let key in configContent) {
    formdata.append(key, configContent[key]);
  }
  return myAxios({
    url: `/api/submitRecoveryLog`,
    method: "post",
    data: formdata,
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
// api/getRecoveryDownloadUrl
export const getRecoveryDownloadUrl = (config) => {
  let configContent = Object.assign(config);
  let formdata = new FormData();
  for (let key in configContent) {
    formdata.append(key, configContent[key]);
  }
  return myAxios({
    url: `/api/getRecoveryDownloadUrl`,
    method: "post",
    data: formdata,
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
